import { IBalances, IDevice, IInvoiceResponse, IMe, IOrder, IUserProfile } from '@/interfaces'
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setMe(state: MainState, payload: IMe) {
        state.me = payload;
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload;
    },
    setOutsideHeaderMobileMenuOpen(state: MainState, payload: boolean) {
        state.outsideHeaderMobileMenuOpen = payload;
    },
    addNotification(state: MainState, payload: AppNotification) {
        console.debug('%c addNotification(%s)', 'color: #b0b', JSON.stringify(payload, null, 4))

        state.notifications.push(payload);
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },
    setOrdersList(state: MainState, payload: IOrder[]) {
        state.ordersList = payload;
    },
    setDevicesList(state: MainState, payload: IDevice[]) {
        state.devicesList = payload;
    },
    setInvoiceResponse(state: MainState, payload: IInvoiceResponse | null) {
        state.invoiceResponse = payload;
    },
    setShowAnimation(state: MainState) {
        state.showAnimation = true;
    },
    setLoading(state: MainState, payload: boolean) {
        state.loading = payload;
    },
    setIsDev(state: MainState, payload: boolean) {
        state.isDev = payload;
    },
    setBalances(state: MainState, payload: IBalances | null) {
        state.balances = payload;
    },
    toggleMobileMenu(state: MainState) {
        state.showMobileMenu = !state.showMobileMenu;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetOutsideHeaderMobileMenuOpen = commit(mutations.setOutsideHeaderMobileMenuOpen);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetMe = commit(mutations.setMe);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetOrdersList = commit(mutations.setOrdersList);
export const commitSetDevicesList = commit(mutations.setDevicesList);
export const commitSetInvoiceResponse = commit(mutations.setInvoiceResponse);
export const commitShowAnimation = commit(mutations.setShowAnimation);
export const commitLoading = commit(mutations.setLoading);
export const commitIsDev = commit(mutations.setIsDev);
export const commitSetBalances = commit(mutations.setBalances);
export const commitToggleMobileMenu = commit(mutations.toggleMobileMenu);
