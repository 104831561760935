
import { Component, Vue } from 'vue-property-decorator'
import { readIsLoggedIn } from '@/store/main/getters'
import { dispatchShowAnimation } from '@/store/main/actions'

@Component({
  metaInfo() {
    return {
      title: 'DexNET',
      meta: [
        {
          name: 'description',
          content: 'DexNet technologies',
        },
      ],
    }
  },
  components: {},
})
export default class App extends Vue {
  get loggedIn() {
    return readIsLoggedIn(this.$store)
  }

  scrollEvent() {
    document.removeEventListener('scroll', this.scrollEvent)
    // console.debug('%c scrollEvent', 'color:gold;')
    dispatchShowAnimation(this.$store)
  }

  mouseMoveEvent() {
    document.removeEventListener('mousemove', this.mouseMoveEvent)
    // console.debug('%c mouseMoveEvent', 'color:orangered;')
    dispatchShowAnimation(this.$store)
  }

  mounted() {
    setTimeout(() => {
      dispatchShowAnimation(this.$store)
    }, 3000)

    setTimeout(() => {
      if (document) {
        document.addEventListener('scroll', this.scrollEvent)
        document.addEventListener('mousemove', this.mouseMoveEvent)
      }
    }, 1000)

  }
}
