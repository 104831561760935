import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile &&
            state.userProfile.is_superuser && state.userProfile.is_active);
    },
    loginError: (state: MainState) => state.logInError,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    outsideHeaderMobileMenuOpen: (state: MainState) => state.outsideHeaderMobileMenuOpen,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
    ordersList: (state: MainState) => state.ordersList,
    devicesList: (state: MainState) => state.devicesList,
    invoiceResponse: (state: MainState) => state.invoiceResponse,
    showAnimation: (state: MainState) => state.showAnimation,
    loading: (state: MainState) => state.loading,
    isDev: (state: MainState) => state.isDev,
    balances: (state: MainState) => state.balances,
    showMobileMenu: (state: MainState) => state.showMobileMenu,
    me: (state: MainState) => state.me,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readOutsideHeaderMobileMenuOpen = read(getters.outsideHeaderMobileMenuOpen);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readOrdersList = read(getters.ordersList);
export const readDevicesList = read(getters.devicesList);
export const readInvoiceResponse = read(getters.invoiceResponse);
export const readShowAnimation = read(getters.showAnimation);
export const readLoading = read(getters.loading);
export const readIsDev = read(getters.isDev);
export const readBalances = read(getters.balances);
export const readShowMobileMenu = read(getters.showMobileMenu);
export const readMe = read(getters.me);
