import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';
import { IBalances } from '@/interfaces'

const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  me: null,
  logInError: false,
  userProfile: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  outsideHeaderMobileMenuOpen: false,
  ordersList: [],
  devicesList: [],
  invoiceResponse: null,
  showAnimation: false,
  loading: false,
  isDev: false,
  balances: null,
  showMobileMenu: false,
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
