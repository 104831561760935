import { api } from '@/api'
import { IBalances, ILandingOrder, IMe, INewOrder, ISignUp } from '@/interfaces'
import router from '@/router'
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils'
import { AxiosError } from 'axios'
import { getStoreAccessors } from 'typesafe-vuex'
import { ActionContext } from 'vuex'
import { State } from '../state'
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetDevicesList,
  commitSetInvoiceResponse,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetOrdersList,
  commitSetToken,
  commitSetUserProfile,
  commitShowAnimation,
  commitLoading,
  commitIsDev,
  commitSetBalances,
  commitToggleMobileMenu,
  commitSetMe,
} from './mutations'
import { AppNotification, MainState } from './state'

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(context: MainContext, payload: { username: string; password: string, code: string }) {
    try {
      const response = await api.submitLogIn(payload)
      const token = response.data.token
      if (token) {
        saveLocalToken(token)
        commitSetToken(context, token)
        commitSetLoggedIn(context, true)
        // commitSetLogInError(context, false);
        // await dispatchGetUserProfile(context);
        // await dispatchRouteLoggedIn(context);
        commitAddNotification(context, { content: 'Logged in', color: 'success' });
        return true
      } else {
        await dispatchLogOut(context)
        return false
      }
    } catch (err) {
      // commitSetLogInError(context, true);
      await dispatchLogOut(context)
      return false
    }
  },
  async actionSubmitLandingOrder(context: MainContext, payload: ILandingOrder) {
    try {
      const response = await api.submitLandingOrder(payload)
      const token = response.data.auth_data.token
      saveLocalToken(token)
      commitSetToken(context, token)
      commitSetLoggedIn(context, true)
      commitSetUserProfile(context, response.data.user)
      // await dispatchGetUserProfile(context);
      return response
    } catch (err) {
      return err.response
      // await dispatchLogOut(context);
    }
  },
  async actionSubmitNewOrder(context: MainContext, payload: INewOrder) {
    try {
      const response = await api.submitNewOrder(context.state.token, payload)
      return response
    } catch (err) {
      await dispatchCheckApiError(context, err)
      return err.response
    }
  },
  async actionSubmitSignUp(context: MainContext, payload: ISignUp) {
    try {
      const response = await api.submitSignUp(payload)
      const token = response.data.token
      if (token) {
        saveLocalToken(token)
        commitSetToken(context, token)
        commitSetLoggedIn(context, true)
        // TODO: подтянуть профиль
        // commitSetUserProfile(context, response.data.user);
        // await dispatchGetUserProfile(context);
        return true
      } else {
        await dispatchLogOut(context)
        return false
      }
    } catch (err) {
      await dispatchLogOut(context)
      return null
    }
  },
  async actionSubmitResetPassword(context: MainContext, payload: { email: string }) {
    try {
      const response = await api.submitResetPassword(payload)
      return true
    } catch (err) {
      return false
    }
  },
  async actionGetOrders(context: MainContext) {
    try {
      const response = await api.getOrdersList(context.state.token)
      if (response.data) {
        commitSetOrdersList(context, response.data)
      }
    } catch (error) {
      commitSetOrdersList(context, [])
      await dispatchCheckApiError(context, error)
    }
  },
  async actionGetDevices(context: MainContext) {
    try {
      const response = await api.getDevicesList(context.state.token)
      if (response.data) {
        commitSetDevicesList(context, response.data)
        // console.debug('%c getDevicesList: %s', 'color:lime;', JSON.stringify(response.data, null, 4))
      }
    } catch (error) {
      commitSetDevicesList(context, [])
      await dispatchCheckApiError(context, error)
    }
  },
  async actionGetOrCreateInvoice(context: MainContext, data: { order: number, standart: string, token?: string }) {
    try {
      const response = await api.getOrCreateInvoice(context.state.token, data)
      if (response.data) {
        commitSetInvoiceResponse(context, response.data)
      }
    } catch (error) {
      commitSetInvoiceResponse(context, null)
      await dispatchCheckApiError(context, error)
    }
  },
  async actionGetPaymentStatus(context: MainContext, data: { address: string, invoice: number }) {
    try {
      const response = await api.getPaymentStatus(context.state.token, data)
      return response.data
    } catch (error) {
      await dispatchCheckApiError(context, error)
      return null
    }
  },
  async actionGetProductSentencesSet(context: MainContext, data: {
    product_sentences_set: string,
    promocode?: string
  }) {
    try {
      const response = await api.getProductSentencesSet(context.state.token, data)
      return response.data
    } catch (error) {
      await dispatchCheckApiError(context, error)
      return null
    }
  },
  async actionSubmitUpdateOrder(
    context: MainContext, data: {
      order: number,
      country?: string,
      city?: string,
      delivery_address?: string,
      starlink_kit?: boolean,
      promocode?: string,
      shipped?: boolean,
    },
  ) {
    try {
      const response = await api.submitUpdateOrder(context.state.token, data)
      return response.data
    } catch (error) {
      await dispatchCheckApiError(context, error)
      return null
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token)
      if (response.data) {
        commitSetUserProfile(context, response.data)
      }
    } catch (error) {
      await dispatchCheckApiError(context, error)
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    // try {
    //     const loadingNotification = { content: 'saving', showProgress: true };
    //     commitAddNotification(context, loadingNotification);
    //     const response = (await Promise.all([
    //         api.updateMe(context.state.token, payload),
    //         await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
    //     ]))[0];
    //     commitSetUserProfile(context, response.data);
    //     commitRemoveNotification(context, loadingNotification);
    //     commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
    // } catch (error) {
    //     await dispatchCheckApiError(context, error);
    // }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token
      if (!token) {
        const localToken = getLocalToken()
        if (localToken) {
          commitSetToken(context, localToken)
          token = localToken
        }
      }
      if (token) {
        try {
          // const response = await api.getMe(token);
          commitSetLoggedIn(context, true)
          // commitSetUserProfile(context, response.data);
        } catch (error) {
          await dispatchRemoveLogIn(context)
        }
      } else {
        await dispatchRemoveLogIn(context)
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken()
    commitSetToken(context, '')
    commitSetLoggedIn(context, false)
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context)
    await dispatchRouteLogOut(context)
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context)
    commitAddNotification(context, { content: 'Logged out', color: 'success' })
  },
  actionRouteLogOut(context: MainContext) {
    if (router.currentRoute.path !== '/' && router.currentRoute.path !== '/login' && router.currentRoute.path !== '/register') {
      // router.push('/');
      window.location.pathname = '/'
    }
  },
  async actionCheckApiError(context: MainContext, payload: AxiosError) {
    if (payload.response!.status === 401) {
      await dispatchLogOut(context)
    }
  },
  actionRouteLoggedIn(context: MainContext) {
    if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
      // router.push('/dashboard');
      window.location.pathname = '/dashboard'
    }
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = { content: 'Sending password recovery email', showProgress: true }
    try {
      commitAddNotification(context, loadingNotification)
      const response = (await Promise.all([
        api.passwordRecovery(payload.username),
        await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0]
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' })
      await dispatchLogOut(context)
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, { color: 'error', content: 'Incorrect username' })
    }
  },
  async resetPassword(context: MainContext, payload: { password: string, token: string }) {
    const loadingNotification = { content: 'Resetting password', showProgress: true }
    try {
      commitAddNotification(context, loadingNotification)
      const response = (await Promise.all([
        api.resetPassword(payload.password, payload.token),
        await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0]
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, { content: 'Password successfully reset', color: 'success' })
      await dispatchLogOut(context)
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, { color: 'error', content: 'Error resetting password' })
    }
  },
  async actionLogInByToken(context: MainContext, payload: { authToken: string, language: string }) {
    try {
      const response = await api.logInByToken(payload.authToken, payload.language)
      const token = response.data.token
      if (token) {
        await saveLocalToken(token)
        await commitSetToken(context, token)
        await commitSetLoggedIn(context, true)
        await commitSetLogInError(context, false)
        await dispatchGetUserProfile(context)
        await dispatchRouteLoggedIn(context)
      } else {
        await dispatchLogOut(context)
      }
    } catch (err) {
      commitSetLogInError(context, true)
      await dispatchFullLogOut(context)
    }
  },
  async actionFullLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context)
    await dispatchRouteLogOut(context)
  },
  actionShowAnimation(context: MainContext) {
    commitShowAnimation(context)
  },
  actionLoading(context: MainContext, payload: boolean) {
    commitLoading(context, payload)
  },
  actionIsDev(context: MainContext, payload: boolean) {
    commitIsDev(context, payload)
  },
  actionSetBalances(context: MainContext, payload: IBalances | null) {
    commitSetBalances(context, payload)
  },
  // actionGetBalances(context: MainContext) {}, // TODO Реализовать релоад балансов
  actionAddNotification(context: MainContext, payload: AppNotification) {
    console.debug('%c actions.ts actionAddNotification(%s)', 'color:lime;', JSON.stringify(payload, null, 4))

    commitAddNotification(context, payload)
  },

  actionToggleMobileMenu(context: MainContext) {
    commitToggleMobileMenu(context)
  },

  actionSetMe(context: MainContext, payload: IMe) {
    commitSetMe(context, payload)
  },

  actionActivateValidator(context: MainContext, payload:any) {
    return api.activateValidator(context.state.token, payload)
  },
  actionCreateValidatorInvite(context: MainContext, payload:any) {
    return api.createValidatorInvite(context.state.token, payload)
  },
  async actionGetReplenishInfo(context: MainContext) {
    try {
      const response = await api.getReplenishInfo(context.state.token)
      return response.data
    } catch (error) {
      return null
    }
  },
}

const { dispatch } = getStoreAccessors<MainState | any, State>('')

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError)
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn)
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile)
export const dispatchLogIn = dispatch(actions.actionLogIn)
export const dispatchLogOut = dispatch(actions.actionLogOut)
export const dispatchSubmitResetPassword = dispatch(actions.actionSubmitResetPassword)
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut)
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn)
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn)
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut)
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile)
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery)
export const dispatchResetPassword = dispatch(actions.resetPassword)
export const dispatchSubmitLandingOrder = dispatch(actions.actionSubmitLandingOrder)
export const dispatchSubmitNewOrder = dispatch(actions.actionSubmitNewOrder)
export const dispatchSubmitSignUp = dispatch(actions.actionSubmitSignUp)
export const dispatchGetOrders = dispatch(actions.actionGetOrders)
export const dispatchGetDevices = dispatch(actions.actionGetDevices)
export const dispatchGetOrCreateInvoice = dispatch(actions.actionGetOrCreateInvoice)
export const dispatchGetPaymentStatus = dispatch(actions.actionGetPaymentStatus)
export const dispatchGetProductSentencesSet = dispatch(actions.actionGetProductSentencesSet)
export const dispatchSubmitUpdateOrder = dispatch(actions.actionSubmitUpdateOrder)
export const dispatchLogInByToken = dispatch(actions.actionLogInByToken)
export const dispatchFullLogOut = dispatch(actions.actionFullLogOut)
export const dispatchShowAnimation = dispatch(actions.actionShowAnimation)
export const dispatchLoading = dispatch(actions.actionLoading)
export const dispatchIsDev = dispatch(actions.actionIsDev)
export const dispatchSetBalances = dispatch(actions.actionSetBalances)
export const dispatchSetMe = dispatch(actions.actionSetMe)
export const dispatchActivateValidator = dispatch(actions.actionActivateValidator)
export const dispatchCreateValidatorInvoice = dispatch(actions.actionCreateValidatorInvite)

export const dispatchAddNotification = dispatch(actions.actionAddNotification)

export const dispatchToggleMobileMenu = dispatch(actions.actionToggleMobileMenu)

export const dispatchGetReplenishInfo = dispatch(actions.actionGetReplenishInfo)
